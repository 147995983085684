import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const carousel = document.querySelector('.carousel-header');

  if (carousel) {
    new Swiper(carousel, {
      slidesPerView: 1,
      loop: true,

      navigation: {
        prevEl: carousel.querySelector('.swiper-button-prev'),
        nextEl: carousel.querySelector('.swiper-button-next'),
      },

      pagination: {
        el: carousel.querySelector('.swiper-pagination'),
        clickable: true,
      },
    });
  }
});
